.pagination {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 50px;
}

.pagination-number {
    font-size: 1.5vw;
    color: #AFC1DF;
    cursor: pointer;
    transition: .5s;
}

.pagination-number:hover {
    scale: 1.3;
}

.pagination-active {
    color: #526F9F;
    font-weight: 500;
}

.pagination-arrow {
    cursor: pointer;
    width: 2vw;
    margin-left: 1vw;
}

.pagination-arrow-md {
    cursor: pointer;
    width: 2vw;
    transform: scaleX(-1);
    margin-right: 1vw;
}

.pagination-arrow__off {
    width: 30px;
    height: 30px;
}

@media (max-width: 992px) {
    .pagination {
        gap: 2vw;
    }

    .pagination-number {
        font-size: 2.3vw;
    }

    .pagination-arrow {
        width: 3vw;
    }

    .pagination-arrow-md {
        width: 3vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .pagination-number {
        font-size: 5vw;
    }

    .pagination-arrow {
        width: 7vw;
    }

    .pagination-arrow-md {
        width: 7vw;
    }

    .pagination {
        gap: 3vw;
    }
}