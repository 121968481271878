.instagram-carusel {
  padding: 20px;
  margin-bottom: 5px;
}

.instagram-carusel__title {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid black;
  padding: 20px;
  width: 200px;
}

.instagram-carusel__box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.instagram-carusel__item {
  width: 125px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: rgb(0, 0, 0);
}

.instagram-carusel__item-picture {
  height: 150px;
  width: 100%;
}

.instagram-carusel__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.instagram-carusel__item-delete {
  background-color: rgb(124 14 14);
  border: none;
  font-size: 13px;
  padding: 5px 15px;
  color: rgb(255 255 255);
  cursor: pointer;
  width: 100%;
}

.instagram-upload {
  margin-top: 20px;
  background: #000000;
  width: auto;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.instagram-upload__input {
  min-width: 260px;
  padding: 5px;
  border: none;
}

.instagram-upload__input:focus {
  outline: none;
}

.instagram-upload__button {
  background-color: rgb(255 255 255);
  padding: 5px 15px;
  color: rgb(0 0 0);
  border: none;
  font-size: 13px;
  width: 150px;
  cursor: pointer;
  min-width: 170px;
}

.instagram-stock {
  display: flex;
  background: #afc1df;
  width: 45vw;
  flex-direction: column;
  padding: 30px;
  gap: 10px;
  margin-top: 20px;
}

.instagram-stock__box {
  display: flex;
  gap: 5vw;
  margin-top: 20px;
  margin-bottom: 20px;
}

.instagram-stock__box__item {
  width: 20vw;
  background: #000000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stock-upload {
  background-color: #000000;
  padding: 30px;
  display: flex;
  gap: 20px;
}

.instagram-stock__box__item-img {
  width: 100%;
  height: 15vw;
}

.instagram-stock__box__item-text {
  font-size: 15px;
  color: white;
}

.instagram-stock__box__item-description {
  font-size: 10px;
  color: white;
  margin-bottom: 20px;
}

.instagram-stock__box__item-delete {
  background-color: rgb(124 14 14);
  border: none;
  font-size: 13px;
  padding: 5px 15px;
  color: rgb(255 255 255);
  cursor: pointer;
  width: 100px;
    margin-top: auto;
}

.instagram-product {
  padding: 20px;
}

.instagram-product__title {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.instagram-product__inputs-wrapper {
  display: flex;
  justify-content: space-between;
}

.instagram-product__inputs {
  display: flex;
  flex-direction: column;
  width: 45vw;
  background: #afc1df;
  padding: 30px;
  gap: 10px;
}

.instagram-product__inputs-foto {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.instagram-product__inputs-foto-wrapper {
  width: 5vw;
  height: 5vw;
  border: .5px solid black;
  position: relative;
}

.instagram-product__inputs-foto-wrapper-delete {
  background-color: rgb(124 14 14);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8vw;
  padding: 0.2vw;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  width: 1vw;
  height: 1vw;
  position: absolute;
  top: -.5vw;
  right: -.5vw;
}

.instagram-product__inputs__other {
  background: #afc1df;
  width: 45vw;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 10px;
}

.instagram-product__button {
  background-color: rgb(255 255 255);
  padding: 5px 15px;
  color: rgb(0 0 0);
  border: none;
  border-radius: 7px;
  font-size: 13px;
  width: 150px;
  cursor: pointer;
}

.instagram-product__box {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 784px;
  overflow-y: auto;
}

.instagram-product__box__display {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.instagram-product__input {
  outline: none;
  padding: 8px;
  font-family: 'Yftoow';
  border: none;
  width: 300px;
}

.instagram-product__box-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 125px;
}

.instagram-product__box-item__display {
  display: flex;
  width: 100%;
  gap: 10px;
}

.instagram-product__box-items__display {
  display: flex;
  gap: 10px;
  width: 100%;
}

.instagram-product__box-item__picture {
  width: 100%;
  height: 150px;
}

.instagram-product__box-item__picture__display {
  width: 50px;
  height: 50px;
}

.instagram-product__box-item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.instagram-product__box-item-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.instagram-product__box-item-info__display {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.instagram-product__box-item-info__title {
  color: white;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px;
  white-space: nowrap;
}

.instagram-product__box-item-info__title__display {
  font-size: 10px;
  padding: 6px;
  width: 25%;
  height: 100%;
}

.instagram-product__box-item-info__price {
  color: white;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px;
  background-color: rgb(85, 96, 117);
}

.instagram-product__box-item-info__price__display {
  color: white;
  font-size: 10px;
  background-color: rgb(85, 96, 117);
  width: 10%;
  height: 100%;
  padding: 6px;
}

.instagram-product__box-item-info__description {
  color: white;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px;
  background-color: rgb(82, 103, 82);
  height: 100%;
}

.instagram-product__box-item-info__description__display {
  font-size: 10px;
  background-color: #afc1df;
  color: black;
  height: 100%;
  width: 15%;
  padding: 6px;
}

.instagram-product__box-item-info__article {
  color: white;
  background-color: rgb(85, 96, 117);
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px;
  height: 100%;
}

.instagram-product__box-item-info__article__display {
  color: white;
  background-color: rgb(85, 96, 117);
  font-size: 10px;
  padding: 6px;
  height: 100%;
  width: 10%;
}

.instagram-product__box-item-info__category {
  width: 100%;
  color: white;
  font-size: 10px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.instagram-product__box-item-info__popular {
  color: white;
  font-size: 10px;
}

.instagram-product__box-item-info__popular__display {
  color: rgb(255, 255, 255);
  font-size: 10px;
  width: 80px;
  background-color: rgb(64, 73, 78);
  height: 100%;
  padding: 6px;
}

.instagram-product__flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
}

.instagram-product__flex-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.instagram-product__select {
  height: 33px;
}

.instagram-product__change {
  display: flex;
  align-items: center;
  gap: 10px;
}

.instagram-product__search {
  padding: 5px;
  outline: none;
  height: 33px;
}

.instagram-product__display {
  display: flex;
  gap: 10px;
}

.instagram-carusel__box2 {
  height: 25px;
}

.instagram-carusel__item-edit {
  background: #afc1df;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 12px;
  font-size: 13px;
  padding: 5px 15px;
  cursor: pointer;
}

.color-md-one {
  background-color: #afc1df;
  ;
  color: black;
}

.color-md-two {
  background-color: rgb(64, 73, 78);
  color: rgb(255, 255, 255);
}

.color-md-three {
  background-color: rgb(68, 149, 162);
  color: black;
}

.instagram-label {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1350px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576.8px) {

  .instagram-carusel {
    padding: 5px;
  }

  .instagram-product__inputs {
    width: auto;
  }

  .instagram-product__select {
    width: 100%;
  }

  .instagram-carusel__box {
    justify-content: center;
  }

  .instagram-upload {
    padding: 20px;
    max-width: 530px;
  }

  .instagram-product__box {
    justify-content: center;
  }

  .instagram-stock__box {
    display: flex;
    gap: 5vw;
    flex-direction: column;
    align-items: center;
  }

  .instagram-stock__box__item {
    width: 100%;
  }

  .instagram-stock__box__item-img {
    height: 30vw;
  }

  .stock-upload {
    width: 100%;
    flex-direction: column;
  }

  .instagram-product__inputs-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .instagram-product__inputs__other {
    width: auto;
  }

  .instagram-product__input {
    width: 100%;
  }
}