.header {
    display: flex;
    flex-direction: column;
}

.header-logo {
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
}

.header-logo__icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2vw;
    width: 12vw;
    position: relative;
}

.header-logo__icons-picture {
    font-size: 2.3vw;
    color: #557094;
    width: 2.5vw;
    cursor: pointer;
    position: relative;
    transition: .8s;
}

.header-logo__item {
    transition: .8s;
}

.header-logo__item:hover {
    scale: 1.1;
}

.header-logo__busket-number {
    position: absolute;
    font-size: 1vw;
    width: 2vw;
    height: 2vw;
    left: 1.5vw;
    top: -1vw;
    background-color: #AFC1DF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 0.5s;
}

.header-logo__busket-number-active {
    width: 3vw;
    height: 3vw;
}

.header-logo__icons-img {
    position: absolute;
    width: 0vw;
    transition: 0.5s;
    top: 0vw;
    left: 0vw;
    z-index: 2;
}

.header-logo__icons-img-active {
    position: absolute;
    width: 25vw;
    top: -2vw;
    left: -2vw;
}

.header-logo__picture {
    width: 20vw;
    margin-bottom: 2vh;
    height: auto;
    transition: .8s;
    cursor: pointer;
}

.header-logo__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header-logo__language {
    display: flex;
    gap: 1vw;
    margin-left: 5vw;
    margin-right: 5vw;
}

.header-logo__language-picture {
    position: relative;
    width: 3vw;
    height: auto;
    cursor: pointer;
}

.header-logo__language-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #557094;
    font-size: 1.3vw;
}

.header-logo__language-text-active {
    color: white;
}

.header-logo__language-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header-logo__phone-wrapper {
    display: flex;
    gap: 1.8vw;
    width: 12vw;
}

.header-logo__phone {
    transition: .7s;
    cursor: pointer;
}

.header-logo__phone-active {
    transform: rotate(100deg);
}

.header-logo__phone-number {
    position: absolute;
    top: 4.6vw;
    right: 12vw;
    transition: .7s;
    font-size: 1.5vw;
    opacity: 0;
    pointer-events: none;
}

.header-logo__phone-number>a {
    color: #526F9F;
}

.header-logo__phone-number-active {
    opacity: 1;
    right: 15vw;
    pointer-events: auto;
}

.header-logo__phone-icon {
    transition: .8s;
    font-size: 2vw;
    color: #557094;
}

.header-logo__phone-icon:hover {
    scale: 1.2;
}

.header-logo__burger__wrapper {
    display: none;
}

.header-buttons {
    display: flex;
    justify-content: center;
    gap: 8vw;
}

.header-buttons__text {
    color: #526F9F;
    font-size: 1.2vw;
    cursor: pointer;
    transition: .8s;
    padding: 10px;
}

.header-buttons__text:hover {
    scale: 1.2;
}

.header-buttons__burger-links {
    display: none;
}

.header-icon-md {
    font-size: 2.3vw;
}

@media (max-width: 1200px) {}

@media (max-width: 992px) {

    .header-logo {
        margin-top: 4vw;
        margin-bottom: 2.5vw;
    }

    .header-logo__icons {
        gap: 4vw;
        width: 20vw;
    }

    .header-logo__icons-picture {
        font-size: 4vw;
        width: 4vw;
    }

    .header-logo__busket-number {
        font-size: 1.6vw;
        width: 3vw;
        height: 3vw;
        left: 2.5vw;
        top: -1vw;
    }

    .header-logo__busket-number-active {
        width: 5vw;
        height: 5vw;
    }

    .header-logo__icons-img-active {
        width: 45vw;
        top: 0vw;
        left: 0vw;
    }

    .header-logo__picture {
        width: 27vw;
        margin-bottom: 2vh;
    }

    .header-logo__phone {
        color: #557094;
        display: flex;
        justify-content: center;
    }

    .header-logo__phone-icon {
        font-size: 3.5vw;
    }

    .header-buttons {
        gap: 5vw;
    }

    .header-buttons__text {
        font-size: 2vw;
    }

    .header-logo__phone-wrapper {
        gap: 4vw;
        width: 20vw;
    }

    .header-icon-md {
        font-size: 3.8vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .header-logo {
        margin-top: 0vw;
        padding-top: 7vw;
        margin-bottom: 2.5vw;
        background-color: #ffffff;
    }

    .header-logo__icons-img-active {
        width: 70vw;
    }

    #header-instagram-icon {
        display: none;
    }

    .header-logo__icons-picture {
        width: 8vw;
        font-size: 7vw;
    }

    .header-logo__picture {
        width: 48vw;
        margin-bottom: 2vh;
    }

    .header-buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 0vw;
        margin-top: -38vw;
        transition: .7s;
        opacity: 0;
        z-index: -1;
    }

    .header-buttons__active {
        margin-top: 0vw;
        opacity: 1;
        z-index: 0;
    }

    .header-buttons__text {
        font-size: 4vw;
    }

    .header-logo__icons {
        width: 16vw;
    }

    .header-logo__phone-wrapper {
        display: none;
    }

    .header-logo__burger__wrapper {
        width: 16vw;
        display: flex;
    }

    .header-logo__burger {
        width: 7vw;
        display: flex;
        flex-direction: column;
        gap: 1vw;
        transition: .5s;
    }

    .header-logo__burger-active {
        transform: rotate(90deg);
    }

    .header-logo__burger-line {
        width: 100%;
        height: 0.8vw;
        background-color: #557094;
        border-radius: 50px;
    }

    .header-logo__phone__mobile {
        /* font-size: 5vw;
        color: #557094; */
    }

    .header-logo__phone-icon {
        font-size: 5vw;
        color: #557094;
    }

    .header-buttons__burger-links {
        display: flex;
        margin-bottom: 4vw;
        flex-direction: row;
        gap: 7vw;
        align-items: center;
    }

    .header-logo__busket-number {
        font-size: 3vw;
        width: 5vw;
        height: 5vw;
        left: 6vw;
        top: -2vw;
    }

    .header-icon-md {
        font-size: 6vw;
    }
}