.top {
    width: 100vw;
    position: relative;
    padding-bottom: 6vw;
}

.top-cloud {
    position: absolute;
    top: -8vw;
    width: 100vw;
}

.top-title {
    color: #526F9F;
    font-size: 3vw;
    font-weight: 200;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8vw;
}

.top-moon {
    position: absolute;
    top: 2vw;
    width: 10vw;
    left: 5vw;
}

.top-star {
    position: absolute;
    top: 10vw;
    width: 7vw;
    left: 23vw;
}

.top-star__small {
    position: absolute;
    top: 3vw;
    width: 4vw;
    left: 17vw;
}

.top-star__right {
    position: absolute;
    top: 5vw;
    width: 6vw;
    right: 19vw;
    transform: scaleX(-1);
}

.top-wrapper {
    padding-top: 19vw;
    position: relative;
    z-index: 2;
}

.top-cloud__bottom {
    position: absolute;
    bottom: -11vw;
    z-index: 1;
    width: 100vw;
}

@media (max-width: 992px) {
    /* .top {
        width: 100vw;
        position: relative;
        padding-bottom: 6vw;
    }

    .top-cloud {
        position: absolute;
        top: -8vw;
        width: 100vw;
    } */

    .top-title {
        font-size: 4vw;
        margin-top: 6vw;
    }

    .top-moon {
        top: -1vw;
        width: 13vw;
        left: 5vw;
    }

    .top-star {
        top: 7vw;
        width: 10vw;
        left: 22vw;
    }

    .top-star__small {
        top: -1vw;
        width: 6vw;
        left: 16vw;
    }

    .top-star__right {
        top: 1vw;
        width: 12vw;
        right: 12vw;
    }

    .top-wrapper {
        padding-top: 22vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    /* .top {
        overflow-x: hidden;
    } */

    .products-map-popular {
        gap: 0vw;
    }

    .products-map-box__popular {
        display: grid;
        justify-content: center;
        gap: 5vw;
    }

    .top-title {
        font-size: 9vw;
        margin-top: 10vw;
        width: 100%;
        text-align: center;
    }

    .top-star {
        top: -7vw;
        width: 12vw;
        left: 53vw;
    }

    .top-moon {
        display: none;
    }

    .top-star__small {
        top: 4vw;
        width: 7vw;
        left: 6vw;
    }

    .top-star__right {
        top: -3vw;
        width: 17vw;
        right: 3vw;
    }

    .products-map-box__popular-item {
        width: 23vw;
    }

    .products-map-box__popular-item__picture {
        width: 60vw;
        height: 65vw;
        margin-top: 3vw;
    }

    .products-map-box__popular-item__picture {
        width: 21vw;
        height: 25vw;
        margin-top: 1vw;
    }

    .products-map-box__popular-item__basket-title {
        font-size: 2.5vw;
        margin-top: 0.7vw;
    }

    .products-map-box__popular-item__name {
        font-size: 2.8vw;
        margin-top: 2vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .products-map-box__popular-item__basket {
        margin-top: 3vw;
        margin-bottom: 7vw;
    }

    .products-map-box__popular-item__basket__picture {
        width: 20vw;
    }

    .top-wrapper {
        padding-top: 28vw;
    }

}