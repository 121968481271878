.pay-info {
    background-color: #fdf4ec;
    margin-top: 12vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    padding-bottom: 10vw;
}

.pay-cloud {
    width: 100vw;
    position: absolute;
    top: 12vw;
    z-index: -1;
}

.pay-info-image-one {
    position: absolute;
    top: 13vw;
    left: 3vw;
    width: 0vw;
    z-index: 1;
    animation: moveBasketStarOne .3s forwards;
    animation-delay: .1s;
}

@keyframes moveBasketStarOne {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 9vw;
        opacity: 1;
    }
}

.pay-info-image-two {
    position: absolute;
    top: 15vw;
    right: 4vw;
    width: 0vw;
    z-index: 1;
    transform: rotate(318deg);
    animation: moveBasketStarTwo .3s forwards;
    animation-delay: .2s;
}

@keyframes moveBasketStarTwo {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 5vw;
        opacity: 1;
    }
}

.pay-info-image-three {
    position: absolute;
    top: 15vw;
    right: 14vw;
    width: 0vw;
    z-index: 1;
    transform: rotate(247deg);
    animation: moveBasketStarThree .3s forwards;
    animation-delay: .4s;
}

@keyframes moveBasketStarThree {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 4vw;
        opacity: 1;
    }
}

.pay-info-image-four {
    position: absolute;
    top: 25vw;
    right: 2vw;
    width: 0vw;
    z-index: 1;
    transform: rotate(125deg);
    animation: moveBasketStarFour .3s forwards;
    animation-delay: .5s;
}

@keyframes moveBasketStarFour {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 6vw;
        opacity: 1;
    }
}

.pay-info__title {
    color: #526F9F;
    font-size: 2.5vw;
    margin-bottom: 2vw;
    font-weight: 200;
}

.pay-info__line {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
}

.pay-info__wrapper {
    width: 70vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 1vw;
}

.pay-info__wrapper-title {
    color: #526F9F;
    font-size: 1.7vw;
    margin-bottom: 2vw;
    margin-top: 3vw;
}

.pay-info__wrapper-description {
    color: #526F9F;
    font-size: 1.1vw;
    margin-left: 1.6vw;
}

.pay-info__wrapper-description > a {
    color: #000000;
}

@media (max-width: 992px) {
    .pay-info {
        background-color: #fdf4ec;
        margin-top: 12vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1vw;
        padding-bottom: 13vw;
    }

    .pay-cloud {
        top: 20vw;
    }

    .pay-info-image-one {
        position: absolute;
        top: 23vw;
        left: 5vw;
        width: 0vw;
        z-index: 1;
        animation: moveBasketStarOne .3s forwards;
        animation-delay: .1s;
    }

    @keyframes moveBasketStarOne {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 10vw;
            opacity: 1;
        }
    }

    .pay-info-image-two {
        position: absolute;
        top: 24vw;
        right: 12vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(318deg);
        animation: moveBasketStarTwo .3s forwards;
        animation-delay: .2s;
    }

    @keyframes moveBasketStarTwo {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 6vw;
            opacity: 1;
        }
    }

    .pay-info-image-three {
        position: absolute;
        top: 31vw;
        right: 24vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(247deg);
        animation: moveBasketStarThree .3s forwards;
        animation-delay: .4s;
    }

    @keyframes moveBasketStarThree {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 5vw;
            opacity: 1;
        }
    }

    .pay-info-image-four {
        position: absolute;
        top: 32vw;
        right: 4vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(125deg);
        animation: moveBasketStarFour .3s forwards;
        animation-delay: .5s;
    }

    @keyframes moveBasketStarFour {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 7vw;
            opacity: 1;
        }
    }

    .pay-info__title {
        color: #526F9F;
        font-size: 2.5vw;
        margin-bottom: 2vw;
    }

    .pay-info__line {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }

    .pay-info__wrapper {
        width: 70vw;
        display: flex;
        flex-direction: column;
        margin-bottom: 1vw;
    }

    .pay-info__wrapper-title {
        font-size: 2vw;
        margin-bottom: 2vw;
        margin-top: 3vw;
    }

    .pay-info__wrapper-description {
        font-size: 1.5vw;
        margin-left: 1.6vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .pay-info {
        position: relative;
        padding-bottom: 21vw;
    }

   .pay-cloud {
       top: -13vw;
   }

    .pay-info__line {
        width: 90vw;
    }

    .pay-info__wrapper {
        width: 82vw;
        margin-bottom: 1vw;
    }

    .pay-info__wrapper-title {
        font-size: 5vw;
        margin-bottom: 9vw;
        margin-top: 3vw;
        text-align: center;
    }

    .pay-info__wrapper-description {
        font-size: 4vw;
        margin-left: 0vw;
        text-align: center;
        line-height: 6vw;
    }

    .pay-info-image-one {
        position: absolute;
        top: -10vw;
        left: 5vw;
        width: 0vw;
        z-index: 1;
        animation: moveBasketStarOne .3s forwards;
        animation-delay: .1s;
    }

    @keyframes moveBasketStarOne {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 10vw;
            opacity: 1;
        }
    }

    .pay-info-image-two {
        position: absolute;
        top: 7vw;
        right: 6vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(318deg);
        animation: moveBasketStarTwo .3s forwards;
        animation-delay: .2s;
    }

    @keyframes moveBasketStarTwo {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 6vw;
            opacity: 1;
        }
    }

    .pay-info-image-three {
        display: none;
    }

    .pay-info-image-four {
        display: none;
    }
}