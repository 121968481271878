.stock {
    background-color: #fdf4ec;
    margin-top: 11vw;
    display: flex;
    justify-content: center;
    padding-bottom: 15vw;
    flex-direction: column;
    align-items: center;
    gap: 10vw;
    position: relative;
    min-height: 80vh;
}

.stock-cloud {
    width: 100vw;
    position: absolute;
    top: -13vw;
    z-index: -1;
}

.stock-image-two {
    position: absolute;
    top: -4vw;
    right: 6vw;
    width: 0vw;
    z-index: 1;
    transform: rotate(318deg);
    animation: stockStarTwo .3s forwards;
    animation-delay: .2s;
}

@keyframes stockStarTwo {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 11vw;
        opacity: 1;
    }
}

.stock-item {
    width: 60vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5vw;
}

.stock-item-image-one {
    position: absolute;
    width: 0vw;
    top: -6vw;
    left: -10vw;
    animation: moveBasketStarOne .5s forwards;
    animation-delay: .3s;
    z-index: 1;
}

.stock-item-image-two {
    position: absolute;
    width: 6vw;
    top: 30vw;
    left: 2vw;
    z-index: 1;
}

.stock-item-image-three {
    position: absolute;
    width: 16vw;
    top: 24vw;
    right: -5vw;
    z-index: 1;
}

.stock-item-image-four {
    position: absolute;
    width: 7vw;
    bottom: -5vw;
    right: -10vw;
    z-index: 1;
}

.stock-item__back-img {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
}

.stock-item__main-img {
    width: 45vw;
    height: 25vw;
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    margin-top: 4vw;
}

.stock-item__title {
    color: white;
    position: relative;
    z-index: 1;
    margin: 1.5vw;
    font-size: 2vw;
    width: 60%;
    font-weight: 200;
    text-align: center;
}

.stock-item__description {
    color: white;
    position: relative;
    font-size: 1.5vw;
    width: 70%;
    z-index: 1;
    font-weight: 200;
    text-align: center;
}

@media (max-width: 576px) {
    .stock-item {
        width: 90vw;
    }

    .stock-item__main-img {
        width: 70vw;
        height: 45vw;
        margin-top: 10vw;
    }

    .stock-item__title {
        font-size: 6vw;
        width: 76%;
    }

    .stock-item__description {
        font-size: 3.5vw;
        width: 75%;
    }

    .stock-item-image-three {
        width: 15vw;
        top: 56vw;
        right: -1vw;
    }

    .stock {
        padding-bottom: 29vw;
        gap: 15vw;
        padding-top: 10vw;
    }

    .stock-image-two {
        position: absolute;
        top: -6vw;
        right: 13vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(318deg);
        animation: stockStarTwo .3s forwards;
        animation-delay: .2s;
    }

    @keyframes stockStarTwo {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 13vw;
            opacity: 1;
        }
    }

}