.category {
    width: 100vw;
}

.category-cloud {
    width: 100vw;
    position: relative;
    top: 0vw;
    z-index: 0;
}

.category-cloud__main {
    width: 100vw;
    position: relative;
    top: -17vw;
    z-index: 0;
    transition: 1s;
}

.category-cloud__active {
    top: -.6vw;
}

.category-one {
    background-color: #fdf4ec;
    padding-bottom: 17vw;
    margin-top: -27vw;
    position: relative;
    display: block;
    transition: 1s;
    cursor: pointer;
}

.category-one__active {
    margin-top: -18vw;
}

.category-one__wrapper {
    display: flex;
    justify-content: center;
    gap: 13vw;
    opacity: 0;
    transition: .7s;
}

.category-one__wrapper__active {
    opacity: 1 !important;
}

.category-one__wrapper-picture {
    width: 25vw;
    height: 25vw;
    position: relative;
    z-index: 1;
}

.category-one__wrapper-picture__background {
    width: 27vw;
    position: absolute;
    left: -4vw;
    top: 1vw;
    z-index: -1;
}

.category-one__wrapper-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2vw;
    width: 25vw;
}

.category-one__wrapper-info-title {
    font-size: 3vw;
    color: #526F9F;
    font-weight: 400;
    transition: .8s;
}

.category-one__wrapper-info-description {
    font-size: 1vw;
    color: black;
    font-weight: 200;
}

.category-one__image-one {
    position: absolute;
    width: 17vw;
    top: -5vw;
    left: 18vw;
    z-index: 1;
    opacity: 0;
    transform: scaleX(-1);
    animation: category-one__image-one .5s forwards;
    animation-delay: .8s;
}

@keyframes category-one__image-one {
    0% {
        opacity: 0;
        width: 17vw;
    }

    100% {
        opacity: 1;
        width: 7vw;
    }
}

.category-one__image-two {
    position: absolute;
    width: 10vw;
    top: -8vw;
    right: 15vw;
    z-index: 1;
    opacity: 0;
    animation: category-one__image-two .5s forwards;
    animation-delay: .7s;
}

@keyframes category-one__image-two {
    0% {
        opacity: 0;
        width: 10vw;
    }

    100% {
        opacity: 1;
        width: 4vw;
    }
}

.category-one__image-three {
    position: absolute;
    width: 12vw;
    top: -4vw;
    right: 7vw;
    z-index: 1;
    opacity: 0;
    transform: scaleX(-1) rotate(28deg);
    animation: category-one__image-three .9s forwards;
    animation-delay: .9s;
}

@keyframes category-one__image-three {
    0% {
        opacity: 0;
        width: 12vw;
        transform: scaleX(-1) rotate(28deg);
    }

    100% {
        opacity: 1;
        width: 14vw;
        transform: scaleX(-1) rotate(10deg);
    }
}

.category-one__image-four {
    position: absolute;
    width: 10vw;
    top: 13vw;
    right: 2vw;
    z-index: 1;
    opacity: 0;
    transform: rotate(28deg);
    animation: category-one__image-four .9s forwards;
    animation-delay: .9s;
}

@keyframes category-one__image-four {
    0% {
        opacity: 0;
        width: 10vw;
        transform: rotate(28deg);
    }

    100% {
        opacity: 1;
        width: 4vw;
        transform: rotate(0deg);
    }
}

.category-one__image-five {
    position: absolute;
    width: 10vw;
    top: 4vw;
    right: 30vw;
    z-index: 1;
    opacity: 0;
    transform: rotate(146deg);
    animation: category-one__image-five .6s forwards;
    animation-delay: 1s;
}

@keyframes category-one__image-five {
    0% {
        opacity: 0;
        width: 10vw;
        transform: rotate(146deg);
    }

    100% {
        opacity: 1;
        width: 7vw;
        transform: rotate(130deg);
    }
}

.category-two {
    background-color: #fdf4ec;
    padding-bottom: 17vw;
    position: relative;
    display: block;
    cursor: pointer;
}

.category-two__wrapper {
    display: flex;
    justify-content: center;
    gap: 13vw;
    opacity: 0;
    transition: 1s;
}

.category-two__wrapper-picture {
    width: 25vw;
    position: relative;
    z-index: 1;
}

.category-two__wrapper-picture__background {
    width: 27vw;
    position: absolute;
    right: -4vw;
    top: 2vw;
    z-index: -1;
}

.category-two__wrapper-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2vw;
    width: 25vw;
}

.category-two__wrapper-info-title {
    font-size: 3vw;
    color: #526F9F;
    font-weight: 400;
}

.category-two__wrapper-info-description {
    font-size: 1vw;
    color: black;
    font-weight: 200;
}

.category-two__image-one {
    position: absolute;
    width: 37vw;
    top: 9vw;
    left: 10vw;
    z-index: 1;
}

.category-two__image-two {
    position: absolute;
    width: 20vw;
    top: -4vw;
    left: 39vw;
    z-index: 0;
    opacity: 0;
    transition: 1s;
}

.category-three {
    background-color: #fdf4ec;
    padding-bottom: 15vw;
    position: relative;
    display: block;
    cursor: pointer;
}

.category-three__wrapper {
    display: flex;
    justify-content: center;
    gap: 15vw;
    opacity: 0;
    transition: 1s;
}

.category-three__wrapper-picture {
    width: 25vw;
    height: 25vw;
    position: relative;
    z-index: 1;
}

.category-three__wrapper-picture__background {
    width: 27vw;
    position: absolute;
    right: 2vw;
    top: 2vw;
    z-index: -1;
}

.category-three__wrapper-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2vw;
    width: 25vw;
}

.category-three__wrapper-info-title {
    font-size: 3vw;
    color: #526F9F;
    font-weight: 400;
}

.category-three__wrapper-info-description {
    font-size: 1vw;
    color: black;
    font-weight: 200;
}

.category-three__image-one {
    position: absolute;
    width: 10vw;
    top: 0vw;
    left: 40vw;
    z-index: 1;
    transition: .3s;
    transform: rotate(-40deg);
    opacity: 0;
}

.category-three__image-one__active {
    width: 20vw;
    top: -5vw;
    transform: rotate(0deg);
}

.category-three__image-five {
    position: absolute;
    width: 6vw;
    top: 0vw;
    right: 20vw;
    z-index: 1;
}

.category-three__image-six {
    position: absolute;
    width: 7vw;
    top: -6vw;
    right: 10vw;
    z-index: 1;
}

.category-three__image-seven {
    position: absolute;
    width: 10vw;
    top: -8vw;
    left: 15vw;
    z-index: 0;
}

.category-four {
    background-color: #fdf4ec;
    padding-bottom: 12vw;
    position: relative;
    display: block;
    cursor: pointer;
}

.category-four__wrapper {
    display: flex;
    justify-content: center;
    gap: 15vw;
}

.category-four__wrapper-picture {
    width: 25vw;
    height: 25vw;
    position: relative;
    z-index: 1;
}

.category-four__wrapper-picture__background {
    width: 27vw;
    position: absolute;
    right: -4vw;
    top: 2vw;
    z-index: -1;
}

.category-four__wrapper-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2vw;
    width: 25vw;
}

.category-four__wrapper-info-title {
    font-size: 3vw;
    color: #526F9F;
    font-weight: 400;
}

.category-four__wrapper-info-description {
    font-size: 1vw;
    color: black;
    font-weight: 200;
}

.category-four__image-one {
    position: absolute;
    width: 4vw;
    top: 13vw;
    right: 40vw;
    z-index: 1;
    transition: 1s;
}

.category-four__image-one__active {
    width: 7vw;
}

.category-four__image-two {
    position: absolute;
    width: 31vw;
    top: -4vw;
    right: 38vw;
    z-index: 0;
}




@media (max-width: 992px) {
    .category-cloud__main {
        top: -27vw;
    }

    .category-cloud__active {
        top: -.6vw;
    }

    .category-one {
        padding-bottom: 19vw;
        margin-top: -37vw;
    }

    .category-one__active {
        margin-top: -13vw;
    }

    .category-one__wrapper {
        gap: 9vw;
        opacity: 0;
    }

    .category-one__wrapper__active {
        opacity: 1;
    }

    .category-one__wrapper-picture {
        width: 38vw;
        height: 38vw;
    }

    .category-one__wrapper-picture__background {
        width: 42vw;
        left: -5vw;
        top: 2vw;
    }

    .category-one__wrapper-info {
        width: 31vw;
        gap: 1vw;
    }

    .category-one__wrapper-info-title {
        font-size: 4vw;
    }

    .category-one__wrapper-info-description {
        font-size: 1.5vw;
    }

    .category-one__image-one {
        position: absolute;
        width: 20vw;
        top: -7vw;
        left: 12vw;
        z-index: 1;
        opacity: 0;
        transform: scaleX(-1);
        animation: category-one__image-one .5s forwards;
        animation-delay: .8s;
    }

    @keyframes category-one__image-one {
        0% {
            opacity: 0;
            width: 20vw;
        }

        100% {
            opacity: 1;
            width: 10vw;
        }
    }

    .category-one__image-two {
        position: absolute;
        width: 10vw;
        top: -12vw;
        right: 12vw;
        z-index: 1;
        opacity: 0;
        animation: category-one__image-two .5s forwards;
        animation-delay: .7s;
    }

    @keyframes category-one__image-two {
        0% {
            opacity: 0;
            width: 10vw;
        }

        100% {
            opacity: 1;
            width: 7vw;
        }
    }

    .category-one__image-three {
        position: absolute;
        width: 12vw;
        top: -1vw;
        right: 9vw;
        z-index: 1;
        opacity: 0;
        transform: scaleX(-1) rotate(28deg);
        animation: category-one__image-three .9s forwards;
        animation-delay: .9s;
    }

    @keyframes category-one__image-three {
        0% {
            opacity: 0;
            width: 12vw;
            transform: scaleX(-1) rotate(28deg);
        }

        100% {
            opacity: 1;
            width: 18vw;
            transform: scaleX(-1) rotate(10deg);
        }
    }

    .category-two__wrapper-picture {
        width: 38vw;
        height: 38vw;
    }

    .category-two__wrapper-picture__background {
        width: 41vw;
        right: -4vw;
        top: 3vw;
    }

    .category-two__wrapper-info-title {
        font-size: 4vw;
    }

    .category-two__wrapper-info-description {
        font-size: 1.5vw;
    }

    .category-two__image-one {
        position: absolute;
        width: 37vw;
        top: 9vw;
        left: 10vw;
        z-index: 1;
    }

    .category-two__image-two {
        width: 31vw;
        top: -6vw;
        left: 23vw;
    }

    .category-three {
        padding-bottom: 17vw;
    }

    .category-three__wrapper-picture {
        width: 38vw;
        height: 38vw;
    }

    .category-three__wrapper-picture__background {
        width: 42vw;
        right: 2vw;
        top: 3vw;
    }

    .category-three__wrapper-info-title {
        font-size: 4vw;
    }

    .category-three__wrapper-info-description {
        font-size: 1.5vw;
    }

    .category-three__image-one {
        width: 28vw;
        top: 2vw;
        left: 46vw;
        transform: rotate(359deg);
    }

    .category-four {
        padding-bottom: 15vw;
    }

    .category-four__wrapper {
        display: flex;
        justify-content: center;
        gap: 15vw;
    }

    .category-four__wrapper-picture {
        width: 38vw;
        height: 38vw;
    }

    .category-four__wrapper-picture__background {
        width: 40vw;
        right: -5vw;
        top: 5vw;
    }

    .category-four__wrapper-info-title {
        font-size: 3vw;
    }

    .category-four__wrapper-info-description {
        font-size: 1.5vw;
    }

    .category-four__image-one {
        width: 11vw;
        top: 6vw;
        right: 47vw;
    }

    .category-four__image-two {
        width: 40vw;
        top: -5vw;
        right: 42vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .category-one {
        z-index: 1;
        padding-top: 5vw;
        padding-bottom: 23vw;
    }

    .category-cloud__main {
        z-index: 1;
    }

    .category-two {
        z-index: 1;
    }

    .category-one__wrapper {
        flex-direction: column;
        align-items: center;
        margin-right: 10vw;
        gap: 12vw;
    }

    .category-one__wrapper-picture {
        width: 60vw;
        height: 60vw;
    }

    .category-one__wrapper-picture__background {
        width: 65vw;
        left: -9vw;
        top: 5vw;
    }

    .category-one__image-one {
        position: absolute;
        width: 30vw;
        top: -11vw;
        left: 2vw;
        z-index: 1;
        opacity: 0;
        transform: scaleX(-1);
        animation: category-one__image-one .5s forwards;
        animation-delay: .8s;
    }

    @keyframes category-one__image-one {
        0% {
            opacity: 0;
            width: 30vw;
        }

        100% {
            opacity: 1;
            width: 20vw;
        }
    }

    .category-one__image-two {
        position: absolute;
        width: 20vw;
        top: -12vw;
        right: 10vw;
        z-index: 1;
        opacity: 0;
        animation: category-one__image-two .5s forwards;
        animation-delay: .7s;
    }

    @keyframes category-one__image-two {
        0% {
            opacity: 0;
            width: 20vw;
        }

        100% {
            opacity: 1;
            width: 10vw;
        }
    }

    .category-one__image-three {
        position: absolute;
        width: 12vw;
        top: -1vw;
        right: 9vw;
        z-index: 1;
        opacity: 0;
        transform: scaleX(-1) rotate(28deg);
        animation: category-one__image-three .9s forwards;
        animation-delay: .9s;
    }

    @keyframes category-one__image-three {
        0% {
            opacity: 0;
            width: 12vw;
            transform: scaleX(-1) rotate(28deg);
        }

        100% {
            opacity: 1;
            width: 25vw;
            transform: scaleX(-1) rotate(10deg);
        }
    }

    .category-one__image-four {
        position: absolute;
        width: 10vw;
        top: -5vw;
        right: 51vw;
        z-index: 1;
        opacity: 0;
        transform: rotate(28deg);
        animation: category-one__image-four .9s forwards;
        animation-delay: .9s;
    }

    @keyframes category-one__image-four {
        0% {
            opacity: 0;
            width: 10vw;
            transform: rotate(28deg);
        }

        100% {
            opacity: 1;
            width: 8vw;
            transform: rotate(0deg);
        }
    }

    .category-one__image-five {
        position: absolute;
        width: 10vw;
        top: 49vw;
        right: 10vw;
        z-index: 1;
        opacity: 0;
        transform: rotate(146deg);
        animation: category-one__image-five .6s forwards;
        animation-delay: 1s;
    }

    @keyframes category-one__image-five {
        0% {
            opacity: 0;
            width: 10vw;
            transform: rotate(146deg);
        }

        100% {
            opacity: 1;
            width: 11vw;
            transform: rotate(130deg);
        }
    }

    .category-one__wrapper-info-title {
        font-size: 9vw;
    }

    .category-one__wrapper-info {
        width: 75vw;
        gap: 3vw;
    }

    .category-one__wrapper-info-description {
        font-size: 3.5vw;
    }

    .category-two__wrapper {
        flex-direction: column-reverse;
        align-items: center;
        margin-left: 10vw;
    }

    .category-two__wrapper-picture {
        width: 60vw;
        height: 60vw;
    }

    .category-two__wrapper-picture__background {
        width: 65vw;
        right: -7vw;
        top: 4vw;
    }

    .category-two__image-two {
        width: 60vw;
        top: -7vw;
        left: 5vw;
    }

    .category-two__wrapper-info-title {
        font-size: 9vw;
        text-align: right;
    }

    .category-two__wrapper-info {
        width: 70vw;
        gap: 3vw;
    }

    .category-two__wrapper-info-description {
        font-size: 3.5vw;
        text-align: right;
    }

    .category-three__wrapper-picture {
        width: 60vw;
        height: 60vw;
    }

    .category-three__wrapper {
        flex-direction: column;
        align-items: center;
        margin-right: 10vw;
    }

    .category-three__wrapper-picture__background {
        width: 67vw;
    }

    .category-three__wrapper-info-title {
        font-size: 9vw;
    }

    .category-three__wrapper-info {
        gap: 3vw;
        width: 70vw;
    }

    .category-three__wrapper-info-description {
        font-size: 3.5vw;
    }

    .category-three__image-one {
        width: 41vw;
        top: -11vw;
        left: 52vw;
        transform: rotate(359deg);
    }

    .category-two {
        padding-bottom: 28vw;
    }

    .category-four__wrapper {
        flex-direction: column;
        align-items: center;
        flex-direction: column-reverse;
        margin-left: 10vw;
    }

    .category-four__wrapper-picture {
        width: 60vw;
        height: 60vw;
    }

    .category-four__wrapper-picture__background {
        width: 64vw;
        right: -8vw;
        top: 5vw;
    }

    .category-four__wrapper-info-title {
        font-size: 9vw;
        text-align: right;
    }

    .category-four__wrapper-info-description {
        font-size: 3.5vw;
        text-align: right;
    }

    .category-four__wrapper-info {
        gap: 3vw;
        width: 70vw;
    }

    .category-four__image-two {
        width: 45vw;
        top: 13vw;
        right: 57vw;
        transform: rotate(315deg);
    }

    .category-four__image-one {
        width: 19vw;
        top: 55vw;
        right: 71vw;
    }

    .category-four {
        padding-bottom: 22vw;
    }

    .category-four__image-one {
        width: 19vw;
        top: -16vw;
        right: 15vw;
    }

    .category-three {
        padding-bottom: 26vw;
    }

    .category-three__image-five {
        width: 17vw;
        top: 20vw;
        right: 3vw;
    }

    .category-three__image-six {
        width: 13vw;
        top: 50vw;
        right: 15vw;
    }

    .category-three__image-seven {
        width: 20vw;
        top: -15vw;
        left: 10vw;
    }

    .category-cloud {
        top: 5vw;
        scale: 1.5;
    }

}