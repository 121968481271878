.item {
    background-color: #fdf4ec;
    margin-top: 12vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vw;
}

.item-wrapper {
    width: 80vw;
}

.item-cloud {
    width: 100vw;
    position: absolute;
    top: 12vw;
    z-index: -1;
}

.item-image-one {
    position: absolute;
    top: 20vw;
    left: 5vw;
    width: 9vw;
    z-index: 1;
}

.item-image-two {
    position: absolute;
    top: 9vw;
    right: 5vw;
    width: 6vw;
    transform: rotate(50deg);
}

.item-image-three {
    position: absolute;
    top: 26vw;
    right: 5vw;
    width: 4vw;
    transform: rotate(324deg);
}

.item-image-four {
    position: absolute;
    top: 17vw;
    right: 15vw;
    width: 5vw;
    transform: rotate(284deg);
}

.item-image-five {
    position: absolute;
    top: -3vw;
    right: 20.5vw;
    width: 17vw;
}

.item-image-six {
    position: absolute;
    animation: moveElement 1s ease-in-out infinite;
    top: -2vw;
    right: 10vw;
    width: 6vw;
    opacity: 1;
}

@keyframes moveElement {
    0% {
        transform: translateX(0) translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-30vw) translateY(-26vh);
        opacity: 0;
    }
}

.item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vw;
}

.item-info__photos {
    display: flex;
    gap: 2vw;
    flex-direction: column;
}

.item-info__photos-image {
    width: 38vw;
    height: 38vw;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
}

.item-info__photos-image__md {
    background-color: white;
}

.item-info__photos-gallery {
    display: flex;
    gap: 3vw;
}

.item-info__photos-gallery-image {
    width: 5vw;
    height: 5vw;
    cursor: pointer;
}

.item-info__photos-link {
    width: 33vw;
    color: #526F9F;
}

.item-info__photos-link__item {
    margin-top: 1vw;
    margin-left: 1vw;
    display: flex;
    align-items: center;
    gap: 20px;
}

.item-info__photos-link__item-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.item-info__photos-description {
    color: #526F9F;
    font-size: 2vw;
    margin-top: 2vw;
}

.item-info__text {
    width: 35vw;
}

.item-info__text-title {
    font-size: 2.3vw;
    margin-bottom: 4vw;
}

.item-info__text-cost {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4vw;
    position: relative;
}

.item-info__text-cost__money {
    color: #526F9F;
    font-size: 2.3vw;
}

.item-info__text-cost__basket {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: 1s;
}

.item-info__text-cost__basket:hover {
    scale: 1.1;
}

.item-info__text-cost__basket-title {
    position: absolute;
    z-index: 1;
    color: white;
    font-size: 1.2vw;
    top: .7vw;
}

.item-info__text-cost__basket__picture {
    width: 13vw;
    z-index: 0;
}

.item-info__text-characteristic {
    font-size: 1.2vw;
    display: flex;
    flex-direction: column;
    gap: .7vw;
}

.item-info__text-description {
    font-size: 1.2vw;
    display: flex;
    flex-direction: column;
    gap: .7vw;
    margin-top: 1.7vw;
}

.item-info__text-delivery {
    color: #0000008f;
    font-size: 1.3vw;
    cursor: pointer;
    transition: .5s;
}

.item-info__text-delivery:hover {
    scale: 1.4;
    color: black;
}

.item-characteristic {
    padding-top: 2vw;
    padding-bottom: 10vw;
    font-size: 1.4vw;
    color: rgba(0, 0, 0, 0.717);
    line-height: 2vw;
}

.item-color {
    font-size: 1.4vw;
    color: #526F9F;
    margin-bottom: 1.6vw;
}

.item-colors-wrapper {
    display: flex;
    padding-bottom: 2vw;
    align-items: center;
    gap: 1vw;
}

.item-colors-wrapper__items {
    display: flex;
    gap: 2vw;
}

.item-colors-wrapper-item {
    width: 20vw;
    height: 25vw;
    background: #afc1df;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5vw;
    cursor: pointer;
}

.item-colors-wrapper-item__picture {
    position: relative;
    width: 6vw;
    height: 6vw;
    cursor: pointer;
}

.item-colors-wrapper-item__picture-color {
    width: 1.7vw;
    height: 1.7vw;
    border-radius: 50%;
    position: absolute;
    top: 5.4vw;
    left: 50%;
    transform: translate(-50%);
}

.item-white {
    background-color: white;
}

.item-gray {
    background-color: gray;
}

.item-vanilla {
    background-color: #beb5a4;
}

.item-wenge {
    background-color: #403229;
}

.item-nut {
    background-color: #795f49;
}

.item-natural {
    background-color: #ae8f6e;
}

.item-pink {
    background-color: pink;
}

.item-blue {
    background-color: rgb(19, 114, 182);
}

.item-anthracite {
    background-color: #6a6b7d;
}

.item-grayAndWhite {
    background-color: gray;
}

.item-sonoma {
    background-color: #c09e70;
}

.item-alyaska {
    background-color: #ac9897;
}

.item-colors-wrapper-item__name {
    color: white;
    font-size: 1.3vw;
}

@media (max-width: 992px) {
    .item {
        margin-top: 13vw;
        padding-bottom: 10vw;
    }

    .item-wrapper {
        width: 85vw;
    }

    .item-cloud {
        top: 20vw;
    }

    .item-image-one {
        top: 27vw;
        left: 2vw;
        width: 13vw;
    }

    .item-image-two {
        top: 25vw;
        right: 8vw;
        width: 5vw;
    }

    .item-image-three {
        top: 38vw;
        right: 2vw;
        width: 8vw;
    }

    .item-image-four {
        top: 27vw;
        right: 29vw;
        width: 7vw;
    }

    .item-image-five {
        top: -4vw;
        right: 17.5vw;
        width: 22vw;
    }

    .item-image-six {
        position: absolute;
        animation: moveElement 1s ease-in-out infinite;
        top: -2vw;
        right: 10vw;
        width: 8vw;
        opacity: 1;
    }

    @keyframes moveElement {
        0% {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }

        100% {
            transform: translateX(-30vw) translateY(-14vh);
            opacity: 0;
        }
    }

    .item-info {
        margin-bottom: 2vw;
    }

    .item-info__photos {
        gap: 4vw;
    }

    .item-info__photos-image {
        width: 42vw;
        height: 42vw;
    }

    .item-info__photos-gallery-image {
        width: 6vw;
        height: 6vw;
    }

    .item-info__photos-link {
        width: 33vw;
    }

    .item-info__photos-link-image {}

    .item-info__photos-link__item {
        margin-top: 1vw;
        margin-left: 1vw;
    }

    .item-info__photos-description {
        font-size: 2vw;
    }

    .item-info__text {
        width: 35vw;
    }

    .item-info__text-title {
        font-size: 2.6vw;
        margin-bottom: 7vw;
    }

    .item-info__text-cost {
        margin-bottom: 7vw;
    }

    .item-info__text-cost__money {
        font-size: 2.6vw;
    }

    .item-info__text-cost__basket-title {
        font-size: 1.5vw;
        top: 0.9vw;
    }

    .item-info__text-cost__basket__picture {
        width: 15vw;
    }

    .item-info__text-characteristic {
        font-size: 1.7vw;
        gap: 1vw;
    }

    .item-info__text-description {
        margin-top: 1.7vw;
        font-size: 1.7vw;
        gap: 1vw;
    }

    .item-characteristic {
        padding-top: 2vw;
        padding-bottom: 10vw;
        font-size: 1.4vw;
        line-height: 2vw;
    }

    .item-color {
        font-size: 2vw;
        margin-bottom: 3vw;
    }

    .item-colors-wrapper {
        padding-bottom: 4vw;
        gap: 0vw;
    }

    .item-colors-wrapper__items {
        gap: 2vw;
    }

    .item-colors-wrapper-item {
        width: 20vw;
        height: 25vw;
        background: #afc1df;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5vw;
        cursor: pointer;
    }

    .item-colors-wrapper-item__picture {
        width: 7vw;
        height: 7vw;
    }

    .item-colors-wrapper-item__picture-color {
        width: 2vw;
        height: 2vw;
        top: 6vw;
    }

    .item-colors-wrapper-item__name {
        color: white;
        font-size: 1.3vw;
    }

    .item-info__text-delivery {
        font-size: 2vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .item-cloud {
        top: -13vw;
        position: relative;
    }

    .item {
        margin-top: 13vw;
        padding-bottom: 14vw;
    }

    .item-info {
        flex-direction: column;
        align-items: center;
    }

    .item-info__photos-image {
        width: 85vw;
        height: 85vw;
    }

    .item-info {
        margin-top: -57vw;
        margin-bottom: 10vw;
    }

    .item-info__photos-gallery-image {
        width: 14vw;
        height: 14vw;
    }

    .item-info__photos-link {
        width: 100%;
    }

    .item-info__photos-gallery {
        gap: 3.8vw;
    }

    .item-info__text {
        width: 100%;
    }

    .item-info__text-title {
        font-size: 6.5vw;
        margin-top: 8vw;
        text-align: center;
        color: #526F9F;
        margin-bottom: 17vw;
    }

    .item-info__text-cost {
        flex-direction: column;
        align-items: center;
        gap: 18vw;
        margin-bottom: 13vw;
    }

    .item-info__text-cost__money {
        font-size: 7vw;
    }

    .item-image-five {
        top: -11vw;
        right: 16.5vw;
        width: 55vw;
    }

    .item-info__text-cost__basket__picture {
        width: 60vw;
    }

    .item-info__text-cost__basket-title {
        font-size: 5vw;
        top: 4vw;
    }

    .item-color {
        font-size: 5vw;
        margin-bottom: 7vw;
    }

    .item-colors-wrapper-item__picture {
        width: 16vw;
        height: 16vw;
    }

    .item-colors-wrapper-item__picture-color {
        width: 6vw;
        height: 6vw;
        top: 13vw;
    }

    .item-colors-wrapper {
        padding-bottom: 14vw;
    }

    .item-info__text-characteristic {
        font-size: 4vw;
        gap: 3vw;
        text-align: center;
    }

    .item-info__photos-description {
        font-size: 5vw;
        margin-top: 8vw;
    }

    .item-info__text-description {
        margin-top: 4vw;
        font-size: 4vw;
        gap: 2vw;
        line-height: 5vw;
    }

    .item-image-one {
        top: -34vw;
        left: -37vw;
        width: 18vw;
        position: relative;
    }

    .item-image-four {
        display: none;
    }

    .item-image-two {
        display: none;
    }

    .item-image-three {
        top: -63vw;
        right: -37vw;
        width: 16vw;
        position: relative;
    }

    .item-image-six {
        position: absolute;
        animation: moveElement 1s ease-in-out infinite;
        top: -15vw;
        right: 0vw;
        width: 30vw;
        opacity: 1;
    }

    @keyframes moveElement {
        0% {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }

        100% {
            transform: translateX(-30vw) translateY(-60vh);
            opacity: 0;
        }
    }

    .item-info__text-delivery {
        font-size: 5vw;
    }

}