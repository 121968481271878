.footer {
    width: 100vw;
    background-color: #526f9f;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-line {
    position: relative;
    top: -3vw;
    width: 100vw;
}

.footer-wrapper {
    display: flex;
    gap: 14vw;
    justify-content: center;
    position: relative;
    top: -7vw;
}

.footer-wrapper__logo {
    display: flex;
    flex-direction: column;
    gap: 3vw;
}

.footer-wrapper__logo-picture {
    width: 30vw;
    cursor: pointer;
}

.footer-wrapper__logo-social {
    display: flex;
    gap: 2vw;
}

.footer-wrapper__logo-social-picture {
    font-size: 2.5vw;
    color: #afc1df;
    cursor: pointer;
}

.footer-wrapper__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .7vw;
}

.footer-wrapper__info-link {
    color: #afc1df;
    font-size: 1.5vw;
    cursor: pointer;
}

.footer-by {
    font-size: 1.5vw;
    color: #afc1df;
    position: relative;
    top: -2.5vw;
    cursor: pointer;
}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .footer {
        padding-bottom: 10vw;
    }

    .footer-wrapper {
        flex-direction: column;
        gap: 7vw;
    }

    .footer-wrapper__logo-picture {
        width: 70vw;
    }

    .footer-wrapper__logo-social {
        gap: 5vw;
        justify-content: center;
    }

    .footer-wrapper__logo-social-picture {
        font-size: 7vw;
    }

    .footer-wrapper__logo {
        gap: 8vw;
    }

    .footer-wrapper__info {
        display: none;
    }

    .footer-wrapper__info-mobile {
        display: flex;
        align-items: center;
        gap: 4vw;
    }

    .footer-wrapper__info-link {
        font-size: 4vw;
    }

    .footer-by {
        font-size: 4vw;
        top: 3vw;
    }

}