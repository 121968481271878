.products-map__pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products-map {
    display: flex;
    justify-content: center;
    gap: 6vw;
    width: 90vw;
}

.products-map-popular {
    display: flex;
    justify-content: center;
    gap: 2vw;
    align-items: center;
}

.products-map-box {
    display: flex;
    flex-wrap: wrap;
    gap: 3vw 3vw;
    width: 100%;
    align-content: flex-start;
}

.products-map-box__popular {
    display: grid;
    grid-template-columns: repeat(3, 23vw);
    justify-content: center;
    justify-items: center;
    gap: 7vw;
}

.products-map-box__item {
    width: 18vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: .4s;
    cursor: pointer;
    padding: 1vw;
}

.products-map-box__item:hover {
    background-color: #AFC1DF;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.106);
}

.products-map-box__item-picture {
    width: 15vw;
    height: 15vw;
}

.products-map-box__item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.products-map-box__item-title {
    color: #557094;
    font-size: 1.2vw;
    margin-top: 1.2vw;
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
}

.products-map-box__popular-item {
    width: 20vw;
    background: #afc1df;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: .4s;
    cursor: pointer;
}

.products-map-box__popular-item__opacity {
    opacity: 0;
}

.products-map-box__popular-item__picture {
    width: 18vw;
    height: 18vw;
    margin-top: 1vw;
}

.products-map-box__popular-item__picture-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.products-map-box__popular-item__name {
    color: white;
    font-size: 1.3vw;
    margin-top: 1.2vw;
    width: 80%;
    text-align: center;
}

.products-map-box__popular-item__basket {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.2vw;
    margin-bottom: 4vw;
    transition: .5s;
}

.products-map-box__popular-item__basket:hover {
    scale: 1.1;
}

.products-map-box__popular-item__basket-title {
    color: #afc1df;
    font-size: 1vw;
    position: absolute;
    z-index: 1;
    margin-top: .4vw;
}

.products-map-box__popular-item__basket__picture {
    position: absolute;
    width: 10vw;
}

.products-map-box__item-title__mobile-img {
    display: none;
}

@media (max-width: 992px) {
    .products-map {
        gap: 12vw;
        margin-top: 3vw;
    }

    .products-map-box {
        gap: 6vw 6vw;
    }

    .products-map-box__item {
        width: 23vw;
        padding: 1vw 1vw 2vw 1vw;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }

    .products-map-box__item:hover {
        background-color: inherit;
        box-shadow: none;
    }

    .products-map-box__item-picture {
        width: 21vw;
        height: 21vw;
    }

    .products-map-box__item-title {
        font-size: 1.6vw;
        margin-top: 2vw;
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .products-map-box__popular-item {
        width: 23vw;
    }

    .products-map-box__popular-item__picture {
        width: 21vw;
        height: 21vw;
        margin-top: 1vw;
    }

    .products-map-box__popular-item__name {
        font-size: 1.6vw;
        margin-top: 1.2vw;
    }

    .products-map-box__popular-item__basket {
        margin-top: 1.2vw;
    }

    .products-map-box__popular-item__basket-title {
        font-size: 1.6vw;
        margin-top: .3vw;
    }

    .products-map-box__popular-item__basket__picture {
        width: 13vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .products-map {
        margin-top: 14vw;
        width: 85vw;
        flex-direction: column;
    }

    .products-map-box__item {
        width: 85vw;
        background-color: inherit;
        box-shadow: none;
    }

    .products-map-box__item-picture {
        width: 80vw;
        height: 80vw;
    }

    .products-map-box__item-title {
        font-size: 5vw;
        margin-top: 4vw;
        text-align: left;
        margin-right: auto;
    }

    .products-map-box__item-title__mobile {
        font-size: 6.4vw;
        margin-top: 3vw;
        display: flex;
        width: 100%;
        align-items: center;
    }

    .products-map-box {
        gap: 15vw;
    }

    .products-map-box__item-title__mobile-img {
        display: block;
        width: 12vw;
        margin-left: auto;
    }


}