.filter-title {
    font-size: 1vw;
    color: white;
    background-color: #557094;
    border-radius: 2vw;
    padding: 1vw 1.8vw;
    display: inline-block;
    margin-bottom: 1vw;
}

.filter-mobile__title {
    display: none;
}

.filter-category {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1vw;
    border-radius: 1vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 4vw;
}

.filter-category-type {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1vw;
    border-radius: 1vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 4vw;
    gap: 1vw;
}

.filter-category__title {
    font-size: .8vw;
    color: #5570949a;
    margin-bottom: .6vw;
}

.filter-category__list {
    font-size: 1vw;
    color: #557094;
    cursor: pointer;
}

.filter-category__list-active {
    font-size: 1vw;
    color: #ffffff;
    background-color: #526F9F;
    border-radius: 1vw;
    padding: .4vw;
    padding-left: .6vw;
}

.filter-button {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.filter-button__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    transition: .5s;
}

.filter-button__wrapper:hover {
  scale: 1.1;
}

.filter-button-picture {
    width: 14vw;
}

.filter-button__click {
    position: absolute;
    z-index: 1;
    border: none;
    background-color: inherit;
    color: white;
    cursor: pointer;
}

.filter-button__clear {
    position: absolute;
    z-index: 1;
    border: none;
    background-color: inherit;
    color: #526F9F;
    cursor: pointer;
}

@media (max-width: 992px) {
    .filter-title {
        font-size: 1.6vw;
        padding: 1vw 2vw;
        margin-bottom: 2vw;
    }

    .filter-category {
        padding: 1.8vw;
    }

    .filter-category-type {
        padding: 1.5vw;
        border-radius: 1vw;
        margin-bottom: 4vw;
        gap: 2vw;
    }

    .filter-category__title {
        font-size: 1.3vw;
        margin-bottom: 1vw;
    }

    .filter-category__list {
        font-size: 1.6vw;
    }

    .filter-category__list-active {
        font-size: 1.6vw;
        border-radius: 1.6vw;
        padding: 1vw;
    }

    .filter-button {
        gap: 2vw;
    }

    .filter-button-picture {
        width: 20vw;
    }

    .filter-button__click {
        font-size: 1.7vw;
    }

    .filter-button__clear {
        font-size: 1.7vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .filter {
        display: none;
    }

    .filter-open_mobile {
        display: block;
    }

    .filter-mobile__title {
        display: block;
        color: #557094;
        text-align: center;
        border: 1.5px solid;
        padding: 2vw;
        border-radius: 20vw;
        width: 40vw;
        margin-left: auto;
        margin-right: auto;
        font-size: 3.5vw;
    }

    .filter-mobile__title-active {
        background-color: #557094;
        color: #fdf4ec;
    }

    .filter-title {
        font-size: 3.2vw;
        padding: 3vw 5vw;
        margin-bottom: 4vw;
        border-radius: 10vw;
    }

    .filter-category__title {
        font-size: 3vw;
        margin-bottom: 1vw;
    }

    .filter-category-type {
        padding: 4vw;
        border-radius: 5vw;
        margin-bottom: 10vw;
        gap: 5vw;
    }

    .filter-category__list {
        font-size: 3.4vw;
    }

    .filter-category__list-active {
        font-size: 3.6vw;
        border-radius: 7vw;
        padding: 2vw;
        padding-left: 3vw;
    }

    .filter-category {
        padding: 4vw;
        border-radius: 5vw;
        margin-bottom: 10vw;
        gap: 5vw;
    }

    .filter-button-picture {
        width: 50vw;
    }

    .filter-button__click {
        font-size: 4vw;
    }

    .filter-button__clear {
        font-size: 4vw;
    }

    .filter-button {
        gap: 6vw;
    }


}