*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  border-radius: 0px;
}

textarea,
select,
input:active,
input:focus {
  outline: none;
}

a,
button {
  text-decoration: none !important;
  color: inherit;
}

button {
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

.fade-out {
  opacity: 0 !important;
}

.checkbox {
  margin-right: 5px;
}