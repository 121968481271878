.order {
    background-color: #fdf4ec;
    margin-top: 9vw;
    display: flex;
    justify-content: space-around;
    padding-bottom: 8vw;
    z-index: 0;
}

.order-cloud {
    position: absolute;
    top: 11vw;
    z-index: -1;
}

.order-input {
    width: 40vw;
    display: flex;
    flex-direction: column;
}

.order-info {
    width: 40vw;
}

.order-info__text {
    font-size: 1.5vw;
    padding-bottom: 1vw;
    border-bottom: 1px solid black;
}

.order-info__contacts-title {
    font-size: 1vw;
    margin-top: 1.5vw;
}

.order-info__contacts {
    display: flex;
    gap: 1vw;
}

.order-info__item {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.215);
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.order-info__item__img {
    width: 5vw;
    height: 5vw;
}

.order-info__item__name {}

.order-info__item__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.order-info__item__cost {
    font-size: 1.5vw;
}

.order-info__item__quantity {
    font-size: .8vw;
    color: rgba(0, 0, 0, 0.4);
}

.order-info__summ {
    display: flex;
    justify-content: space-between;
    margin-top: 3vw;
    align-items: flex-end;
}

.order-info__summ-text {
    font-size: 1.5vw;
}

.order-info__summ-number {
    font-size: 2vw;
}

.order-image__final {
    width: 0vw;
    height: 0vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: order-image__final 3s forwards;
    opacity: 0;
    z-index: 6;
    /* pointer-events: none; */
    transition: 1s;
}

@keyframes order-image__final {
    0% {
        width: 0vw;
        height: 0vw;
        opacity: 0;
    }

    30% {
        width: 50vw;
        height: 50vw;
        opacity: 1;
    }

    60% {
        width: 50vw;
        height: 50vw;
        opacity: 1;
    }

    100% {
        width: 95vw;
        height: 95vw;
        opacity: 0;
    }
}

@media (max-width: 992px) {
    .order {
        margin-top: 9vw;
        display: flex;
        justify-content: space-around;
        padding-bottom: 8vw;
        flex-direction: column-reverse;
        align-items: center;
        min-height: 90vw;
    }

    .order-cloud {
        top: 19vw;
    }

    .order-input {
        width: 80vw;
        display: flex;
        flex-direction: column;
    }

    .order-info {
        width: 80vw;
    }

    .order-info__text {
        font-size: 2.5vw;
        padding-bottom: 2vw;
    }

    .order-info__contacts-title {
        font-size: 2vw;
        margin-top: 4vw;
    }

    .order-info__contacts {
        gap: 3vw;
    }

    .order-info__item {
        grid-template-columns: 20% 50% 30%;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 3vw;
        padding-bottom: 3vw;
    }

    .order-info__item__img {
        width: 11vw;
        height: 11vw;
    }

    .order-info__item__name {
        font-size: 2vw;
    }

    .order-info__item__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: .6vw;
    }

    .order-info__item__cost {
        font-size: 2.2vw;
    }

    .order-info__item__quantity {
        font-size: 1.5vw;
    }

    .order-info__summ {
        margin-top: 6vw;
        margin-bottom: 7vw;
    }

    .order-info__summ-text {
        font-size: 3vw;
    }

    .order-info__summ-number {
        font-size: 3vw;
    }

    @keyframes order-image__final {
        0% {
            width: 0vw;
            height: 0vw;
            opacity: 0;
        }

        30% {
            width: 70vw;
            height: 70vw;
            opacity: 1;
        }

        60% {
            width: 70vw;
            height: 70vw;
            opacity: 1;
        }

        100% {
            width: 120vw;
            height: 120vw;
            opacity: 0;
        }
    }

}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .order {
        position: relative;
        padding-bottom: 14vw;
        z-index: 1;
    }

    .order-cloud {
        top: -11vw;
    }

    .order-info__text {
        font-size: 6vw;
        padding-bottom: 6vw;
        margin-top: 4vw;
    }

    .order-info {
        width: 87vw;
    }

    .order-info__item {
        grid-template-columns: 20% 50% 30%;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
    }

    .order-info__item__img {
        width: 13vw;
        height: 13vw;
    }

    .order-info__item__name {
        font-size: 3.3vw;
    }

    .order-info__item__cost {
        font-size: 4.2vw;
    }

    .order-info__item__quantity {
        font-size: 2.5vw;
    }

    .order-info__summ-text {
        font-size: 4vw;
    }

    .order-info__summ-number {
        font-size: 4vw;
    }

    .order-input {
        width: 87vw;
    }

    .order-info__contacts-title {
        font-size: 4vw;
        margin-top: 10vw;
    }

    @keyframes order-image__final {
        0% {
            width: 0vw;
            height: 0vw;
            opacity: 0;
        }

        30% {
            width: 90vw;
            height: 90vw;
            opacity: 1;
        }

        60% {
            width: 90vw;
            height: 90vw;
            opacity: 1;
        }

        100% {
            width: 170vw;
            height: 170vw;
            opacity: 0;
        }
    }

}