.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.not-found-title {
    font-size: 4vw;
    margin-top: 7vw;
    margin-left: 10%;
    color: #526f9f;
    margin-bottom: 6vw;
}

.not-found-lonk {
    color: black;
    font-size: 2vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13vw;
}

@media (max-width: 992px) {
    .not-found {
        min-height: 70vh;
    }

    .not-found-title {
        font-size: 6vw;
    }

    .not-found-lonk {
        font-size: 4vw;
    }
}

@media (max-width: 576px) {
    .not-found {
        min-height: 45vh;
    }
}