.instagram {
    background-color: #fdf4ec;
    width: 100vw;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vw;
    padding-top: 4vw;
}

.instagram-title {
    color: #526F9F;
    text-align: center;
    font-size: 4vw;
    width: 40vw;
    margin-top: 10vw;
    letter-spacing: .1vw;
    line-height: 4vw;
}

.instagram-icon__heard-wrapper {
    width: 10vw;
    position: absolute;
    right: 15vw;
    top: 10vw;
}

.instagram-icon-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.instagram-icon__arrow-wrapper {
    width: 20vw;
    position: absolute;
    left: 12vw;
    top: 15vw;
}

.instagram-one__wrapper {
    display: flex;
    gap: 4vw;
    margin-top: 10vw;
}

.instagram-one__wrapper-item {
    width: 20vw;
    height: 20vw;
    transition: .7s;
}

.instagram-one__wrapper-item:hover {
    scale: 1.05;
}

.instagram-one__wrapper-item__null {
    width: 20vw;
    height: 20vw;
    background-color: #fdf4ec;
}

.instagram-icon__love-wrapper {
    width: 25vw;
    position: absolute;
    right: 5vw;
    top: 38vw;
}

.instagram-two__wrapper {
    display: flex;
    gap: 4vw;
    margin-top: 4vw;
    margin-bottom: 5vw;
}

.instagram-one__wrapper-item__info {
    width: 20vw;
    display: flex;
    align-items: center;
}

.instagram-icon__circle-wrapper {
    position: absolute;
    width: 25vw;
    left: 1vw;
}

.instagram-one__wrapper-item__info-text {
    color: #526F9F;
    font-size: 2vw;
    text-align: center;
}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .instagram-title {
        font-size: 9vw;
        width: 90vw;
        line-height: 10vw;
    }

    .instagram-icon__arrow-wrapper {
        display: none;
    }

    .instagram-icon__love-wrapper {
        display: none;
    }

    .instagram-icon__heard-wrapper {
        width: 15vw;
        right: 4vw;
        top: 20vw;
    }

    .instagram-one__wrapper-item__null {
        display: none;
    }

    .instagram-one__wrapper {
        width: 85%;
    }

    .instagram-one__wrapper-item {
        width: 39vw;
        height: 39vw;
    }

    .instagram-one__wrapper {
        gap: 7vw;
        justify-content: space-between;
    }

    .instagram-two__wrapper {
        gap: 4vw;
        margin-top: 6vw;
        margin-bottom: 5vw;
        width: 85%;
        justify-content: space-between;
    }

    .instagram-icon__circle-wrapper {
        width: 50vw;
        left: 1vw;
    }

    .instagram-one__wrapper-item__info-text {
        font-size: 4.3vw;
    }

    .instagram-one__wrapper-item__info {
        width: 39vw;
        display: flex;
        align-items: center;
    }

}