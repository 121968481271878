.category-item-wrapper {
    background-color: #fdf4ec;
    margin-top: -15vw;
    position: relative;
    padding-bottom: 13vw;
}

.category-item__title {
    display: none;
}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .category-item {
        width: 100vw;
    }

    .category-item-wrapper {
        margin-top: -20vw;
    }

    .category-item__title {
        display: block;
        color: #557094;
        position: relative;
        z-index: 1;
        top: -13vw;
        font-size: 8vw;
        text-align: center;
    }
}