.basket {
    background-color: #fdf4ec;
    margin-top: 12vw;
    display: flex;
    justify-content: center;
    min-height: 50vw;
}

.basket-image-one {
    position: absolute;
    top: 13vw;
    left: 3vw;
    width: 0vw;
    z-index: 1;
    animation: moveBasketStarOne .3s forwards;
    animation-delay: .1s;
}

@keyframes moveBasketStarOne {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 9vw;
        opacity: 1;
    }
}

.basket-image-two {
    position: absolute;
    top: 10vw;
    right: 2vw;
    width: 0vw;
    z-index: 1;
    transform: rotate(318deg);
    animation: moveBasketStarTwo .3s forwards;
    animation-delay: .2s;
}

@keyframes moveBasketStarTwo {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 5vw;
        opacity: 1;
    }
}

.basket-image-three {
    position: absolute;
    top: 15vw;
    right: 14vw;
    width: 0vw;
    z-index: 1;
    transform: rotate(247deg);
    animation: moveBasketStarThree .3s forwards;
    animation-delay: .4s;
}

@keyframes moveBasketStarThree {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 4vw;
        opacity: 1;
    }
}

.basket-image-four {
    position: absolute;
    top: 25vw;
    right: 2vw;
    width: 0vw;
    z-index: 1;
    transform: rotate(125deg);
    animation: moveBasketStarFour .3s forwards;
    animation-delay: .5s;
}

@keyframes moveBasketStarFour {
    0% {
        width: 0vw;
        opacity: 0;
    }

    100% {
        width: 6vw;
        opacity: 1;
    }
}

.basket-wrapper {
    width: 80vw;
}

.basket-title {
    color: #526F9F;
    font-size: 2vw;
    margin-bottom: 1vw;
    animation: basketOpacity .5s forwards;
    opacity: 0;
}

@keyframes basketOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.basket-line {
    width: 100%;
    animation: basketOpacity .5s forwards;
    opacity: 0;
}

.basket-item {
    display: grid;
    grid-template-columns: 12vw 32vw 8vw 14vw 8vw;
    align-items: center;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
    animation: basketOpacity .5s forwards;
    opacity: 0;
}

.basket-item__picture {
    width: 10vw;
    height: 10vw;
}

.basket-item__name {
    color: #526F9F;
    font-size: 1.5vw;
    width: 90%;
}

.basket-item__plus-minus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.basket-item__plus-minus__img {
    width: 3vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.basket-item__plus-minus-number {
    position: relative;
    z-index: 1;
    font-size: 1.5vw;
    color: #526F9F;
}

.basket-item__cost {
    color: #526F9F;
    font-size: 1.5vw;
    margin-left: auto;
}

.basket-item__delete {
    width: 1vw;
    margin-left: auto;
    cursor: pointer;
}

.basket-sum__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 8vw;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 9vw;
}

.basket-sum {
    color: #526F9F;
    font-size: 2vw;
}

.basket-sum__number__wrapper {
    position: relative;
    width: 20vw;
    display: flex;
    justify-content: flex-end;
}

.basket-sum__number__img {
    width: 20vw;
    top: -4vw;
    right: -4vw;
    position: absolute;
}

.basket-sum__number {
    color: #526F9F;
    font-size: 2vw;
}

.basket-button__wrapper {
    margin-bottom: 11vw;
    width: 17vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    transition: .5s;
}

.basket-button__wrapper:hover {
    scale: 1.1;
}

.basket-button__img {
    width: 100%;
}

.basket-button__text {
    color: white;
    position: absolute;
    margin-bottom: .5vw;
    font-size: 1vw;
}

@media (max-width: 992px) {
    .basket {
        margin-top: 14vw;
        min-height: 50vw;
    }

    .basket-image-one {
        position: absolute;
        top: 23vw;
        left: 5vw;
        width: 0vw;
        z-index: 1;
        animation: moveBasketStarOne .3s forwards;
        animation-delay: .1s;
    }

    @keyframes moveBasketStarOne {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 10vw;
            opacity: 1;
        }
    }

    .basket-image-two {
        position: absolute;
        top: 24vw;
        right: 12vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(318deg);
        animation: moveBasketStarTwo .3s forwards;
        animation-delay: .2s;
    }

    @keyframes moveBasketStarTwo {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 6vw;
            opacity: 1;
        }
    }

    .basket-image-three {
        position: absolute;
        top: 31vw;
        right: 24vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(247deg);
        animation: moveBasketStarThree .3s forwards;
        animation-delay: .4s;
    }

    @keyframes moveBasketStarThree {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 5vw;
            opacity: 1;
        }
    }

    .basket-image-four {
        position: absolute;
        top: 32vw;
        right: 4vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(125deg);
        animation: moveBasketStarFour .3s forwards;
        animation-delay: .5s;
    }

    @keyframes moveBasketStarFour {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 7vw;
            opacity: 1;
        }
    }

    .basket-wrapper {
        width: 85vw;
    }

    .basket-title {
        font-size: 2.6vw;
        margin-bottom: 1vw;
        animation: basketOpacity .5s forwards;
        opacity: 0;
    }

    @keyframes basketOpacity {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .basket-line {
        width: 100%;
        animation: basketOpacity .5s forwards;
        opacity: 0;
    }

    .basket-item {
        grid-template-columns: 14vw 33vw 12vw 17vw 6vw;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        margin-top: 3vw;
        margin-bottom: 3vw;
        animation: basketOpacity .5s forwards;
        opacity: 0;
    }

    .basket-item__picture {
        width: 12vw;
        height: 12vw;
    }

    .basket-item__name {
        font-size: 2vw;
    }

    .basket-item__plus-minus__img {
        width: 5vw;
    }

    .basket-item__plus-minus-number {
        font-size: 2vw;
    }

    .basket-item__cost {
        font-size: 2.2vw;
    }

    .basket-item__delete {
        width: 1vw;
        margin-left: auto;
        cursor: pointer;
    }

    .basket-sum__wrapper {
        margin-top: 11vw;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-bottom: 12vw;
    }

    .basket-sum {
        font-size: 3vw;
    }

    .basket-sum__number__wrapper {
        position: relative;
        width: 20vw;
        display: flex;
        justify-content: flex-end;
    }

    .basket-sum__number__img {
        width: 28vw;
        top: -6vw;
        right: -4vw;
    }

    .basket-sum__number {
        font-size: 3vw;
    }

    .basket-button__wrapper {
        margin-bottom: 13vw;
        width: 22vw;
    }

    .basket-button__text {
        margin-bottom: .5vw;
        font-size: 1.5vw;
    }

}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .basket {
        margin-top: 14vw;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .basket-title {
        font-size: 8vw;
        margin-bottom: 5vw;
        animation: basketOpacity .5s forwards;
        opacity: 0;
        text-align: center;
    }

    .basket-wrapper {
        width: 85vw;
        margin-top: -25vw;
        min-height: 90vh;
    }

    .basket-image-one {
        position: absolute;
        top: -9vw;
        left: 5vw;
        width: 0vw;
        z-index: 1;
        animation: moveBasketStarOne .3s forwards;
        animation-delay: .1s;
    }

    @keyframes moveBasketStarOne {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 15vw;
            opacity: 1;
        }
    }

    .basket-image-two {
        position: absolute;
        top: 0vw;
        right: 8vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(318deg);
        animation: moveBasketStarTwo .3s forwards;
        animation-delay: .2s;
    }

    @keyframes moveBasketStarTwo {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 9vw;
            opacity: 1;
        }
    }

    .basket-image-three {
        position: absolute;
        top: 9vw;
        right: 80vw;
        width: 0vw;
        z-index: 1;
        transform: rotate(247deg);
        animation: moveBasketStarThree .3s forwards;
        animation-delay: .4s;
    }

    @keyframes moveBasketStarThree {
        0% {
            width: 0vw;
            opacity: 0;
        }

        100% {
            width: 13vw;
            opacity: 1;
        }
    }

    .basket-image-four {
        display: none;
    }

    .basket-item {
        display: flex;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        margin-top: 3vw;
        margin-bottom: 3vw;
        animation: basketOpacity .5s forwards;
        opacity: 0;
        flex-direction: column;
        align-items: center;
    }

    .basket-item__picture {
        width: 65vw;
        height: 65vw;
    }

    .basket-item__name {
        font-size: 5vw;
        text-align: center;
        width: 75%;
        margin-top: 5vw;
        margin-bottom: 9vw;
    }

    .basket-item__plus-minus-number {
        font-size: 5vw;
    }

    .basket-item__plus-minus__img {
        width: 12vw;
    }

    .basket-item__plus-minus {
        gap: 10vw;
        margin-bottom: 10vw;
    }

    .basket-item__cost {
        font-size: 6vw;
        margin-right: auto;
    }

    .basket-item__delete {
        width: 3vw;
    }

    .basket-sum__wrapper {
        display: flex;
        margin-top: 8vw;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-bottom: 15vw;
        flex-direction: column;
        align-items: center;
        gap: 10vw;
    }

    .basket-sum {
        font-size: 6vw;
    }

    .basket-sum__number {
        font-size: 5vw;
    }

    .basket-sum__number__img {
        width: 34vw;
        top: -6vw;
        right: 2vw;
    }

    .basket-sum__number__wrapper {
        width: 35vw;
        justify-content: center;
    }

    .basket-button__wrapper {
        margin-right: auto;
        width: 55vw;
        margin-bottom: 17vw;
    }

    .basket-button__text {
        margin-bottom: 0.5vw;
        font-size: 4vw;
    }
}