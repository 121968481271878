.setting {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 100px;
}

.setting__button {
    display: flex;
    gap: 30px;
}

.setting__button-title {
    font-size: var(--SmallFont);
    font-weight: 700;
    background-color: black;
    color: white;
    padding: 15px 15px;
    text-transform: uppercase;
    width: 230px;
    text-align: center;
    cursor: pointer;
}

.setting__inputs-window {
    display: flex;
    justify-content: space-between;
    z-index: 0;
    margin-bottom: 30px;
    position: relative;
}

.setting__inputs {
    display: flex;
    flex-direction: column;
    width: 45vw;
    padding: 10px;
    gap: 15px;
    border: 1px solid black;
}

.setting__inputs-file {
    min-height: 40px;
    margin-top: 20px;
}

.setting__inputs-select {
    padding: 10px;
    height: 100%;
    font-size: var(--StandardFont);
}

.setting__inputs-select-checkbox {
    display: flex;
    gap: 10px;
    cursor: pointer;
    font-size: var(--StandardFont);
}

.setting__inputs-foto {
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.setting__inputs-foto-wrapper {
    width: 5vw;
    height: 5vw;
    border: .5px solid black;
    position: relative;
}

.setting__inputs-foto-arrow-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.setting__inputs-foto-arrow {
    width: 20px;
}

.setting__inputs-foto-arrow--up {
    transform: rotate(-180deg);
}

.setting__inputs-foto-delete {
    background-color: rgb(124 14 14);
    display: flex;
    justify-content: center;
    font-size: .8vw;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    width: 1vw;
    height: 1vw;
    position: absolute;
    top: -.5vw;
    right: -.5vw;
}

.setting__search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.setting__search-select {
    width: 15%;
    height: 33px;
    padding-left: 10px;
    border: 1px solid black;
    font-size: var(--StandardFont);
}

.setting__search-select-change {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: var(--StandardFont);
}

.setting__item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.setting__item {
    display: flex;
    gap: 10px;
    width: 100%;
}

.setting__item-info {
    display: flex;
    width: 100%;
    gap: 10px;
}

.setting__item-info-picture {
    width: 50px;
    height: 60px;
}

.setting__item-info-wrapper {
    display: flex;
    gap: 5px;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
}

.setting__item-info-title {
    font-size: var(--MediumFont);
    padding: 6px;
    width: 35%;
    height: 100%;
    border: 1px solid black;
}

.setting__item-info-others {
    font-size: var(--MediumFont);
    padding: 6px;
    width: 15%;
    height: 100%;
    border: 1px solid black;
}

.setting-product__flex-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.setting-product__search {
    padding: 5px;
    outline: none;
    height: 33px;
}

.setting__item-edit {
    background: black;
    color: white;
    font-size: var(--SmallFont);
    padding: 5px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
}

.setting__item-delete {
    background: rgb(94, 0, 0);
    color: white;
    font-size: var(--SmallFont);
    padding: 5px 15px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
}

.setting__item-info-picture__none {
    background-color: rgb(120, 8, 8);
    width: 100%;
    height: 100%;
}

.setting__item-info-others__input {
    padding-left: 6px;
    border: 1px solid black;
}

@media (max-width: 920px) {
    .setting {
        padding: 5px;
        gap: 10px;
    }

    .setting__button {
        padding: 10px 0px 0px;
    }

    .setting__inputs-window {
        flex-direction: column;
    }

    .setting__button-title {
        font-size: var(--MediumFont);
        padding: 15px 15px;
        width: 150px;
    }

    .setting__progress {
        font-size: var(--MediumFont);
        width: 45%;
    }

    .setting__inputs {
        width: 100%;
        border: none;
    }

    .setting__item-info-title {
        font-size: var(--MediumFont);
        width: 100%;
    }

    .setting__inputs-select {
        font-size: var(--MediumFont);
        border: 1px solid #00000036;
        color: black;
        border-radius: 5px;
        padding: 15px;
    }

    .setting__search {
        gap: 10px;
        flex-direction: column;
        align-items: flex-start;
    }

    .setting__item-info-others {
        font-size: var(--MediumFont);
        width: 100%;
    }

    .setting__search-select {
        height: 33px;
        font-size: var(--MediumFont);
        color: black;
        padding-left: 10px;
        width: 100%;
    }

    .setting__search-select-change {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: var(--MediumFont);
    }

    .setting__item-edit {
        padding: 5px 12px;
        text-transform: uppercase;
    }

    .setting__item-delete {
        padding: 5px 7px;
    }

    .setting__inputs-foto {
        flex-direction: row;
        padding: 25px 10px;
        gap: 20px;
        flex-wrap: wrap;
    }

    .setting__inputs-foto-delete {
        font-size: 10px;
        padding: 0.2vw;
        width: 15px;
        height: 15px;
        top: -10px;
        right: -10px;
    }

    .setting__inputs-foto-arrow {
        width: 16px;
    }

    .setting__inputs-foto-arrow {
        transform: rotate(-90deg);
    }

    .setting__inputs-foto-arrow--up {
        transform: rotate(90deg);
    }

    .setting__inputs-foto-wrapper {
        width: 50px;
        height: 50px;
    }

    .setting__item-info-others__input {
        width: 100%;
        margin-top: 6px;
    }

    .setting__item-info-wrapper {
        height: fit-content;
    }
}

:root {
    --TinyFont: 10px;
    --SmallFont: 12px;
    --MediumFont: 14px;
    --StandardFont: 16px;
    --LargeFont: 17px;
    --ExtraLargeFont: 22px;
    --BigFont: 26px;
    --HugeFont: 40px;
    --OversizedFont: 48px;
}