.admin {
    display: flex;
    justify-content: center;
    height: 30vw;
    align-items: center;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.636);
    border-radius: 20px;
    padding: 30px;
    gap: 10px;
}

@media (max-width: 992px) {
    .admin {
        height: fit-content;
    }
}