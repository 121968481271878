.about {
    background-color: #fdf4ec;
    margin-top: 12vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    padding-bottom: 10vw;
}

.about-cloud {
    width: 100vw;
    position: absolute;
    top: 12vw;
    z-index: -1;
}

.about-image-one {
    position: absolute;
    top: 13vw;
    right: 3vw;
    width: 15vw;
    z-index: 1;
    opacity: 0;
    animation: moveAboutStarOne .3s forwards;
    animation-delay: .1s;
}

@keyframes moveAboutStarOne {
    0% {
        width: 15vw;
        opacity: 0;
    }

    100% {
        width: 9vw;
        opacity: 1;
    }
}

.about-image-two {
    position: absolute;
    top: 17vw;
    left: 8vw;
    width: 20vw;
    z-index: 1;
    opacity: 0;
    transform: rotate(318deg);
    animation: moveAboutStarTwo .7s forwards;
    animation-delay: .2s;
}

@keyframes moveAboutStarTwo {
    0% {
        width: 20vw;
        opacity: 0;
        transform: rotate(318deg);
    }

    100% {
        width: 10vw;
        opacity: 1;
        transform: rotate(338deg);
    }
}

.about-image-three {
    position: absolute;
    top: 15vw;
    right: 14vw;
    width: 10vw;
    z-index: 1;
    opacity: 0;
    transform: rotate(247deg);
    animation: moveAboutStarThree .3s forwards;
    animation-delay: .4s;
}

@keyframes moveAboutStarThree {
    0% {
        width: 10vw;
        opacity: 0;
    }

    100% {
        width: 4vw;
        opacity: 1;
    }
}

.about-image-four {
    position: absolute;
    top: 25vw;
    right: 2vw;
    width: 13vw;
    z-index: 1;
    opacity: 0;
    transform: rotate(125deg);
    animation: moveAboutStarFour .4s forwards;
    animation-delay: .5s;
}

@keyframes moveAboutStarFour {
    0% {
        width: 13vw;
        opacity: 0;
    }

    100% {
        width: 6vw;
        opacity: 1;
    }
}

.about-description {
    color: #526F9F;
    font-size: 1.5vw;
    margin: 1vw;
}

.about-text {
    color: #526F9F;
    font-size: 1.3vw;
    width: 70vw;
    line-height: 1.6vw;
    display: flex;
    gap: 1vw;
    flex-direction: column;
}

.about-text__link {
    color: #526F9F;
    font-size: 1.5vw;
    margin-top: 3vw;
    transition: 1s;
    padding: 2vw;
}

.about-text__link:hover {
    scale: 1.08;
}

.about-day {
    width: 86vw;
    margin-top: 7vw;
}

@media (max-width: 992px) {
    .about {
        background-color: #fdf4ec;
        margin-top: 12vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1vw;
        padding-bottom: 10vw;
    }

    .about-cloud {
        top: 20vw;
    }

    .about-image-one {
        position: absolute;
        top: 30vw;
        right: 17vw;
        width: 20vw;
        z-index: 1;
        opacity: 0;
        animation: moveAboutStarOne .5s forwards;
        animation-delay: .1s;
    }

    @keyframes moveAboutStarOne {
        0% {
            width: 20vw;
            opacity: 0;
        }

        100% {
            width: 10vw;
            opacity: 1;
        }
    }

    .about-image-two {
        position: absolute;
        top: 26vw;
        left: 9vw;
        width: 25vw;
        z-index: 1;
        opacity: 0;
        transform: rotate(318deg);
        animation: moveAboutStarTwo .7s forwards;
        animation-delay: .2s;
    }

    @keyframes moveAboutStarTwo {
        0% {
            width: 25vw;
            opacity: 0;
            transform: rotate(318deg);
        }

        100% {
            width: 13vw;
            opacity: 1;
            transform: rotate(338deg);
        }
    }

    .about-image-three {
        position: absolute;
        top: 25vw;
        right: 11vw;
        width: 15vw;
        z-index: 1;
        opacity: 0;
        transform: rotate(247deg);
        animation: moveAboutStarThree .3s forwards;
        animation-delay: .4s;
    }

    @keyframes moveAboutStarThree {
        0% {
            width: 15vw;
            opacity: 0;
        }

        100% {
            width: 5vw;
            opacity: 1;
        }
    }

    .about-image-four {
        position: absolute;
        top: 33vw;
        right: 5vw;
        width: 17vw;
        z-index: 1;
        opacity: 0;
        transform: rotate(125deg);
        animation: moveAboutStarFour .4s forwards;
        animation-delay: .5s;
    }

    @keyframes moveAboutStarFour {
        0% {
            width: 17vw;
            opacity: 0;
        }

        100% {
            width: 6vw;
            opacity: 1;
        }
    }

    .about-description {
        font-size: 2.5vw;
        margin: 2vw;
    }

    .about-text {
        font-size: 1.9vw;
        width: 70vw;
        line-height: 2.4vw;
    }

    .about-text__link {
        font-size: 2.5vw;
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {

    .about {
        padding-bottom: 16vw;
        gap: 4vw;
        position: relative;
    }

    .about-cloud {
        top: -16vw;
    }

    .pay-info__title {
        font-size: 8vw;
        margin-bottom: 2vw;
    }

    .about-description {
        font-size: 5vw;
        margin: 4vw;
        text-align: center;
    }

    .about-text {
        font-size: 4vw;
        width: 85vw;
        line-height: 6vw;
    }

    .about-image-one {
        position: absolute;
        top: -7vw;
        right: 17vw;
        width: 30vw;
        z-index: 1;
        opacity: 0;
        animation: moveAboutStarOne .5s forwards;
        animation-delay: .1s;
    }

    @keyframes moveAboutStarOne {
        0% {
            width: 30vw;
            opacity: 0;
        }

        100% {
            width: 10vw;
            opacity: 1;
        }
    }

    .about-image-two {
        position: absolute;
        top: -5vw;
        left: 9vw;
        width: 25vw;
        z-index: 1;
        opacity: 0;
        transform: rotate(318deg);
        animation: moveAboutStarTwo .7s forwards;
        animation-delay: .2s;
    }

    @keyframes moveAboutStarTwo {
        0% {
            width: 25vw;
            opacity: 0;
            transform: rotate(318deg);
        }

        100% {
            width: 13vw;
            opacity: 1;
            transform: rotate(338deg);
        }
    }

    .about-image-three {
        display: none;
    }

    .about-image-four {
        display: none;
    }

    .about-text__link {
        font-size: 4.5vw;
        width: 90vw;
    }

}